import React from 'react';
import { Link } from 'gatsby';
import { ItemsGrid, ItemStyles } from '../styles/Grids';
import Card from './Card';

export default function ItemGrid({ items }) {
   //console.log('items', items);
   //     <Link to="/ropa" className="more">Ver más...</Link>
  return (
    <ItemsGrid>
      {items.map((item) => (
        <ItemStyles key={item.id}>
          <Card image={item.img} title={item.title} link={item.link}  ></Card>
        </ItemStyles>
      ))} 
    </ItemsGrid>
  );
}
