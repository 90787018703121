import styled from 'styled-components';

export const HomePageGrid = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  margin: 1rem auto;
  padding: 2.5rem;
  /*box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.044);*/
  background: transparent;
  gap: 2rem;
  --columns: 1;
  grid-template-columns: repeat(var(--columns), minmax(auto, 1fr));


  /*
  &:before,
  &:after {
    content: " ";
    display: block;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 4px;
  } */

  /*&:before {
    border: 2px dashed #0d2b5e;
  }

  &:after {
      border: 2px dashed rgba(20, 20, 20, .30);
      top: 5px; 
      left: 5px; 
      margin-top: 1px;
      margin-left: 1px;
  }*/

  @media (max-width: 800px) {
    --columns: 1;
    padding: 0.25rem;
  }
`;

export const ItemsGrid = styled.div`
  --columns: 2;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(var(--columns), 420px);
  justify-content: center;

  .more {
    grid-column: 1 / span var(--columns);
    margin: 1.2rem 0;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    .more {
      grid-column: 1 / span 1;
      margin: 1.5rem 0;
    }
  }
`;

// Single Grid Item (for home page)
export const ItemStyles = styled.div`
  text-align: center;
  position: relative;
  img {
    height: 400px;
    object-fit: cover;
    font-size: 0;
  }
  p {
   /* top: 0;
    transform: rotate(-2deg) translateY(-10px);
    position: absolute;*/
    width: 100%;
    /*left: 0;*/
    margin: 0;
    font-size: 2rem;
    font-size: clamp(12px, 5vw, 20px);
  }
  h3 {
    padding-bottom: 2px;
  }
  .mark {
    /*display: inline;*/
    
  }
  @keyframes shine {
    from {
      background-position: 200%;
    }
    to {
      background-position: -40px;
    }
  }
  img.loading {
    --shine: white;
    --background: var(--grey);
    background-image: linear-gradient(
      90deg,
      var(--background) 0px,
      var(--shine) 40px,
      var(--background) 80px
    );
    background-size: 500px;
    animation: shine 1s infinite linear;
  }
`;
