import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import MiniLink from './MiniLink';
import { sections } from './Nav';

import coaching from '../assets/images/COACH_BL.png';

import consultoria from '../assets/images/CONSULTORÍA_BL.png';
import auditoria from '../assets/images/AUDITORIA_BL.png';

const ServiceStyles = styled.div`
  display: grid;
  position: relative;

  padding: 0 0.5rem;
  margin-bottom: 1rem;
  grid-template-columns: repeat(3, 1fr);

  a {
    font-size: 2rem;
    text-decoration: none;
    color: var(--red);
    display: block;

    &:hover {
      color: var(--lightgreen);
      text-decoration: underline;
    }

    @media (max-width: 800px) {
      font-size: 1.5rem;
    }

    /* &[aria-current='page'] {
      color: var(--black);
    }*/
  }

  @media (max-width: 600px) {
    --columns: 4;
    margin-bottom: 2rem;
    border-bottom: 2px solid var(--grey);
    padding-bottom: 2rem;

    ul {
      grid-template-rows: auto auto;
      grid-template-columns: repeat(var(--columns), 1fr);
      justify-items: center;
      &.sticked {
        grid-template-columns: repeat(5, auto);
        padding-top: 2rem;
      }

      li {
        a {
          font-size: 1.2rem;
        }
        .sticked {
          display: none;
        }

        &.shopping-cart {
          svg,
          span {
            font-size: 1.2rem;
          }
        }
      }
    }
    .logo-item {
      order: 0;
      grid-column: 1 / -1;
    }
    .logo {
      transform: none;
    }
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    ul {
      &.sticked {
        grid-template-columns: repeat(5, auto);
        padding-top: 2rem;
      }
    }
  }
`;

export default function Services({ active, className }) {
  const customWidth = {
    width: '150%',
    maxWidth: '150%',
    gridColumn: '2 / span 2',
  };

  return (
    <ServiceStyles>
      <MiniLink borderColor="blue" customWidth={customWidth}>
        <div className={active === sections.COACHING ? 'active' : ''}>
          <Link to={sections.COACHING} className={className}>
            <img src={coaching} />
            COACHING
          </Link>
        </div>
      </MiniLink>
      <MiniLink borderColor="yellow" customWidth={customWidth}>
        <div className={active === sections.CONSULTORIA ? 'active' : ''}>
          <Link to={sections.CONSULTORIA} className={className}>
            <img src={consultoria} />
            CONSULTORÍA
          </Link>
        </div>
      </MiniLink>
      <MiniLink borderColor="black" customWidth={customWidth}>
        <div className={active === sections.AUDITORIA ? 'active' : ''}>
          <Link to="/" className={className}>
            <img src={auditoria} />
            AUDITORÍA
          </Link>
        </div>
      </MiniLink>
    </ServiceStyles>
  );
}
