import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const HomeFooterStyles = styled.div`
  display: block;
  height: auto;
  background-color: #ffffffab;
  backdrop-filter: blur(52px);
  padding: 1rem 0;

.Home-footer-section {
    margin: 3.2rem 0 4.5rem 0;
    p {
        &.valores {
            padding: 0 15%;
            max-width: 720px;
            margin: 0 auto;
        }
        
    }
}

  `;

export default function HomeFooter({ children }) {

  return (
    <HomeFooterStyles>
        {children}
    </HomeFooterStyles>
  );
}
