import React from 'react';
import { graphql, Link } from 'gatsby';
import { HomePageGrid } from '../styles/Grids';
import LoadingGrid from '../components/LoadingGrid';
import ItemGrid from '../components/ItemGrid';
import Services from '../components/Services';
import MiniLink from '../components/MiniLink';

import frase from '../assets/images/WEB-FERMANDO-SANDOVAL.png';
import Card from '../components/Card';
import potencial from '../assets/images/potencial.png';
import fernando from '../assets/images/foto-fernando.jpg';
import HomeFooter from '../components/HomeFooter';
import Empresas from '../components/Empresas';



function HotSlices({ hotSlices }) {
  const nodes = [{ img: potencial,
    title: "Liberando el potencial",
    link:"/liberando-el-potencial",
  },
{ img: fernando,
    title: "Sobre mí",
    link:"/sobre-mi",
  },
];
  return (
    <HomePageGrid>
      <h1 className="center">
        <span className="head "></span>
      </h1>
      {!nodes && <LoadingGrid count={3} />}{' '}
      {nodes && !nodes?.length && <p>Nothin' in the Case</p>}
      {nodes?.length && <ItemGrid items={nodes} /> }
    </HomePageGrid>
  );
}

function FooterArticles({ link, children }) {
  return (
    <div class="Home-footer-section">
        <MiniLink borderColor="yellow">
          <div className="head">
            <Link to={link.uri}>{link.name}</Link>
          </div>
        </MiniLink>
        <div> {children} </div>

    </div>
  );
}

export default function HomePage({ data, pageContext }) {
  //console.log(data?.site?.siteMetadata?.links);
  const { ropaNew } = [];

  return (
    <div className="center">
      <p></p>
      <h3 className="pd-md head ">
        <img src={frase} />
      </h3>
      <Services className="head" />
      <div>
        <HotSlices hotSlices={ropaNew?.nodes} />
      </div>
      <br />

    <HomeFooter>
      {
        data?.site?.siteMetadata?.links.map(
          (link) => <FooterArticles link={link}>
            {
              link.name === "VALORES" &&
              <p className="valores">
                La focalización, la actualización, el fomento de buenas relaciones interpersonales y laborales, el compromiso con el trabajo, la generación de confianza con las personas y organizaciones que asisto y su acompañamiento seguro.
              </p> 
            }
            {
              link.name === "EMPRESAS ACOMPAÑADAS" &&
              <div>
                <p>NACIONALES E INTERNACIONALES</p>
                <Empresas />
              </div>
            }
            
          </FooterArticles>
        )
      }
      </HomeFooter>

      
    </div>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        links {
          uri
          name
        }
      }
    }
  }
`;
