import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import emp1 from '../assets/images/LOGOS_PNG/ble.png';
import emp2 from '../assets/images/LOGOS_PNG/cepsa.png';
import emp3 from '../assets/images/LOGOS_PNG/harinera.png';
import emp4 from '../assets/images/LOGOS_PNG/herh.png';
import emp5 from '../assets/images/LOGOS_PNG/Jorge_Tadeo_Lozano_University.png';
import emp6 from '../assets/images/LOGOS_PNG/Logo-Enriko-Productos.png';
import emp7 from '../assets/images/LOGOS_PNG/logoVision.png';
import emp8 from '../assets/images/LOGOS_PNG/SAINC.png';
import emp9 from '../assets/images/LOGOS_PNG/sucroal.png';
import emp10 from '../assets/images/LOGOS_PNG/univalle2.png';
import emp11 from '../assets/images/LOGOS_PNG/Kerry.png';
import emp12 from '../assets/images/LOGOS_PNG/edea.png';


const EmpresasStyles = styled.div`
  display: grid;
  width: 100%;
  height: auto;

  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr) 3rem;

  justify-content: center;
  justify-items: center;
  align-items: center;
  background-color: #dcdbdb;
  padding: 1rem 0;

  .odd {
    grid-column: 2;
  }

  .odd2 {
    grid-column: 4;
  }

  a {
    grid-column: 3;
    grid-row: 4 / span 5;
  }

  div {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    img {
      max-width: 20rem;
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(12, 1fr) 4rem;

    .odd {
      grid-column: 1;
    }

    .odd2 {
      grid-column: 1;
    }

    a {
      grid-column: 1;
      grid-row: 13 / span 1;
    }
  }
`;

function EmpLogo({ className, img }) {
  return (
    <div className={className}>
      <img src={img} />
    </div>
  );
}

export default function Empresas() {
  return (
    <EmpresasStyles>
      <EmpLogo img={emp1} />
      <EmpLogo img={emp2} />
      <EmpLogo img={emp3} />
      <EmpLogo img={emp4} />
      <EmpLogo img={emp5} />

      <EmpLogo img={emp6} />
      <EmpLogo img={emp7} />
      <EmpLogo img={emp8} />
      <EmpLogo img={emp9} />
      <EmpLogo img={emp10} />

      <EmpLogo className="odd" img={emp11} />
      <EmpLogo className="odd2" img={emp12} />

      <Link to="/empresas">
        <p>VER MÁS</p>
      </Link>
    </EmpresasStyles>
  );
}
