import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const CardStyles = styled.div`
  display: block;
  width: 100%;
  height: auto;
  div {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    background-color: #313030;

    h2 {
      /*background-color: #00000099;*/
      position: absolute;
      margin: 0;
      width: 100%;
      height: 6rem;
      color: var(--white);

      bottom: 0.1rem;
      padding-top: 3rem;
      text-align: center;
      padding-left: 1rem;
      
    }
     img {
      mix-blend-mode: overlay;

      &:hover {
        mix-blend-mode: normal;
      }
     }

  }

  @media (max-width: 800px) {
    div {
      h2 {
        height: auto;
        bottom: 2.5rem;
        padding-top: 1rem;
      }
    }
  }

  `;

export default function Card({ image, title, link }) {

  return (
    <CardStyles>
      <Link to={link}>
        <div>
          <img src={image} />
          <h2 className="ft-head">{title}</h2>
        </div>
      </Link>
    </CardStyles>
  );
}
